import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import validateEnv, { EnvError } from 'env';

(async () => {

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  try {

    validateEnv();

    const App = await import('App');

    root.render(
      <React.StrictMode>
        <App.default />
      </React.StrictMode>
    );      
  } catch(e) {
    console.error(e)
    root.render(
      <div>
        <h1>Could not start app</h1>
        <h2>Contact developer team!</h2>
      </div>
    )
  }
})()



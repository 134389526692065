
enum EnvVarKeys {
    FirebaseApiKey='REACT_APP_FIREBASE_APIKEY',
    FirebaseAuthDomain='REACT_APP_FIREBASE_AUTH_DOMAIN',
    AppApiHost='REACT_APP_APP_API_HOST',
    MuiLicenseKey='REACT_APP_MUI_LICENSE_KEY',
};

export { EnvVarKeys };

const REQUIRED_ENV_VARS = [
    EnvVarKeys.FirebaseApiKey,
    EnvVarKeys.FirebaseAuthDomain,
    EnvVarKeys.AppApiHost,
]

class EnvError extends Error {
    constructor(message: string) {
        super(message);
    }
}

export { EnvError };

const getEnvVarOrFail = (key: string) => {
    const val = process.env[key];
    if(!val) throw new EnvError(`Required env var: ${key} not found in process`);
    return val;
}

export { getEnvVarOrFail };

const validateEnv = () => {
    for(const key of REQUIRED_ENV_VARS) {
        getEnvVarOrFail(key);
    }
};

export default validateEnv;